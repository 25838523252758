/*
* General styles
*/
body {
  font-family: 'Gudea', sans-serif;
  line-height: 1.55;
}
hr {
  border-color: #f2ebbf;
}

/*
* Typography
*/
h1, h2, h3, h4, h5, .btn-primary, .nav-pills, .menu .item .info h4, .navbar  {
  font-family: 'Rancho', cursive;
}
h2 {
  font-size: 60px;
  color: #f06060;
  margin-bottom: 25px;
}
h2 a, h2 a:hover, h2 a:focus {
  color: #f06060;
}
h2 a:after {
  background-image: url('../img/theme3/headline_underline.png');
  height: 6px;
}
h4 {
  color: #8cbeb2;
  font-size: 32px;
  font-weight: 400;
}
.text-light {
  color: #5c4b51;
  font-size: 17px;
  background: #f2ebbf;
}
.text-white h4 {
  color: #8cbeb2;
}

/*
* Preloader
*/
.preloader {
  background: #f06060;
}

/*
* Elements
*/
.btn-primary {
  color: #fff;
  background-color: #8cbeb2;
  font-weight: 400;
  font-size: 35px;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active,.btn-primary:active:focus, .btn-primary:active:hover {
  background: #f06060;
  color: #ffffff;
  outline: none;
}

/*
* Slider
*/
.slider .controls span {
  background-image: url('../img/theme3/our-specials_arrows.png');
}

/*
* Navbar
*/
.main-header .navbar-menu {
  background: #f2ebbf;
}
.navbar li a, .navbar li a:focus {
  font-size: 32px;
  text-transform: initial;
  padding: 20px 10px;
  color: #5c4b51;
  padding: 18px 10px 15px 12px;
}
.navbar li a:hover {
  color: #f06060;
}
.main-header .navbar-menu:before,
.main-header .navbar-menu:after {
  display: none;
}
.navbar-brand_name {
  font-size: 105px;
  color: #f06060;
}
.navbar-inverse {
  margin-bottom: 0;
}
.navbar-inverse .navbar-nav>li>a {
  color: #f2ebbf;
  font-size: 35px;
}
.navbar-inverse .navbar-nav>li>a:hover {
  color: #c0b283;
}
.navbar-toggle {
  border-color: #f06060;
}
.navbar-toggle .icon-bar {
  background-color: #f06060;
}

/*
* Social Buttons
*/
.social-buttons li a {
  padding: 0;
}
.social-buttons li a path {
  fill: #5c4b51;
}
.social-buttons li a:hover path {
  fill: #f06060;
}

/*
* Header section
*/
.main-header {
  background-image: url('../img/theme3/header_bg.jpg');
  background-position: center top;
}
.main-header:after {
  display: none;
}
.main-header .main-header__phone {
  margin-top: 15px;
  color: #5c4b51;
  font-weight: bold;
  font-size: 26px;
  font-family: 'Gudea', sans-serif;
}
.main-header .social-buttons {
  margin-top: 20px;
}

/*
* Our specials
*/
.our-specials {
  font-size: 18px;
  color: #5c4b51;
}
.our-specials__item .image {
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
}
.our-specials__item .price .currency {
  color: #3f393b;
}
.our-specials__item .price sup {
  font-weight: 400;
}

/*
* Menu
*/
.menu .content {
  -webkit-border-bottom-left-radius: 0;
     -moz-border-radius-bottomleft: 0;
          border-bottom-left-radius: 0;
  -webkit-border-bottom-right-radius: 0;
     -moz-border-radius-bottomright: 0;
          border-bottom-right-radius: 0;
}
.menu .nav-pills li a {
  background: #f2ebbf;
  font-size: 35px;
  font-weight: bold;
  color: #5c4b51;
}
.menu .nav-pills li a:hover, .menu .nav-pills li a:focus, .menu .nav-pills .active a {
  background: #8cbeb2;
  color: #fff;
}
.menu .nav-pills .active a:after {
  border-color: #8cbeb2 transparent transparent transparent;
}
.menu .item {
  color: #5c4b51;
}
.menu .item .info .price .currency {
  color: #66635a;
}
.menu .item .info h4 {
  margin-bottom: 5px;
  color: #5c4b51;
  font-size: 24px;
}

/*
* Events
*/
.events__event-item .content {
  background: RGBA(58, 53, 57, 90);
  font-size: 16px;
  color: #fff;
}
.events__event-item h4 {
  color: #8cbeb2;
}
.events__event-item .date {
  font-size: 20px;
  color: #5c4b51;
  background: #f2ebbf;
  -webkit-border-bottom-left-radius: 130px;
     -moz-border-radius-bottomleft: 130px;
          border-bottom-left-radius: 130px;
  -webkit-border-bottom-right-radius: 130px;
     -moz-border-radius-bottomright: 130px;
          border-bottom-right-radius: 130px;
  width: 130px;
  height: 60px;
  padding: 13px 33px;
}

/*
* Contacts
*/
.contacts .map__info-block .image .welcome {
  font-size: 25px;
  font-weight: 400;
}
.contacts .map__info-block .info {
  font-size: 20px;
  background: #ffffff;
}

/*
* Footer
*/
.footer .subscribe {
  font-size: 19px;
  line-height: 1.55;
}
.footer .subscribe h2 {
  color: #fff;
}
.footer .subscribe:after {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4c6173+0,f2e9d8+100 */
  background: rgb(76,97,115); /* Old browsers */
  background: -moz-linear-gradient(left,  rgba(76,97,115,1) 0%, rgba(242,233,216,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(76,97,115,1) 0%,rgba(242,233,216,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(rgba(76,97,115,1)), to(rgba(242,233,216,1)));
  background: -webkit-linear-gradient(left, rgba(76,97,115,1) 0%, rgba(242,233,216,1) 100%);
  background: -o-linear-gradient(left, rgba(76,97,115,1) 0%, rgba(242,233,216,1) 100%);
  background: linear-gradient(to right,  rgba(76,97,115,1) 0%,rgba(242,233,216,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c6173', endColorstr='#f2e9d8',GradientType=1 ); /* IE6-9 */
  opacity: 0.4;
}
.footer__info, .footer__info .circle:before {
  background: #5c4b51;
}
.footer__info {
  padding-top: 0;
}
.footer__info .navbar-brand_name {
  color: #ffffff;
}
.footer__info .navbar-brand {
  position: static;
  margin: 0;
  line-height: 1;
}
.footer__info .social-buttons {
  margin: 30px 0;
}
.footer__info .social-buttons li a path {
  fill: #ffffff;
}
.footer__info .social-buttons li a:hover path {
  fill: #f06060;
}